<script lang="ts" setup>
import { emitter } from '~/events';

const route = useRoute()
const menu = ref<HTMLElement>()
const activeItem = ref<Element>()
const menuBorder = ref<HTMLElement>()
const whatsapp = ref<string>('553135001363');

const authStore = useAuthStore()

const offsetMenuBorder = () => {
  const offsetActiveItem = activeItem.value!.getBoundingClientRect();
  if (menu.value && menuBorder.value) {
    const left = Math.floor(offsetActiveItem.left - menu.value.offsetLeft - (menuBorder.value.offsetWidth - offsetActiveItem.width) / 2) + "px";
    menuBorder.value!.style.transform = `translate3d(${left}, 0 , 0)`;
  }
}

const switchMenu = () => {
  let item = menu.value?.querySelector(`[href="${route.path}"]`) as Element
  if (!item) item = menu.value?.querySelector('.menu__item.mipix') as Element

  menu.value?.style.removeProperty("--timeOut");

  if (activeItem.value == item) return;

  if (activeItem.value) {
    activeItem.value.classList.remove("active");
  }

  item.classList.add("active");
  activeItem.value = item;

  offsetMenuBorder();
}

const openCashbox = (type: 'deposit' | 'withdraw' = 'deposit') => {
  if(!authStore.isLoggedIn) {
    navigateTo({ name: 'auth-login' })
    return
  }
  emitter.emit('cashbox-modal', { type, action: 'open' })
}

onMounted(() => {
  menuBorder.value = menu.value?.querySelector(".menu__border") as HTMLElement
  activeItem.value = menu.value?.querySelector(".active") as Element

  offsetMenuBorder();

  window.addEventListener("resize", () => {
    offsetMenuBorder();
    menu.value?.style.setProperty("--timeOut", "none");
  })

  switchMenu()
})

watch(() => route.path, () => switchMenu())
</script>

<template>
  <div>
    <menu class="menu lg:!hidden" ref="menu">
      <nuxt-link :to="{ name: 'account-refers' }" class="menu__item">
        <div class="flex flex-col gap-0.5 justify-around items-center">
          <img src="@/assets/AMIGOS.svg" class="w-6 mt-0.5" />
          <div class="w-full flex justify-center items-center">
            <span class="text-base">+</span>
            <span class="text-[0.5]">Amigos</span>
          </div>
        </div>
      </nuxt-link>

      <div class="menu__item" @click="openCashbox()">
        <div class="flex flex-col gap-1 justify-around items-center">
          <img src="@/assets/SAQUE.svg" class="w-6" />
          <div class="w-full flex justify-center items-center">
            <span class="text-[0.5]">Saques</span>
          </div>
        </div>
      </div>

      <nuxt-link :to="{ name: 'index' }" class="menu__item mipix active">
        <img src="@/assets/HOME.svg" class="w-10" />
      </nuxt-link>

      <nuxt-link :to="{ name: 'account-overview' }" class="menu__item">
        <div class="flex flex-col gap-1 justify-around items-center">
          <img src="@/assets/CARTEIRA.svg" class="w-6" />
          <div class="w-full flex justify-center items-center">
            <span class="text-[0.5]">Carteira</span>
          </div>
        </div>
      </nuxt-link>

      <a :href="`https://api.whatsapp.com/send?phone=${whatsapp}&text=Ol%C3%A1%2C%20MiPix%21`" target="_blank" class="menu__item">
        <div class="flex flex-col gap-1 justify-around items-center">
          <img src="@/assets/SUPORTE.svg" class="w-7" />
          <div class="w-full flex justify-center items-center">
            <span class="text-[0.5]">Suporte</span>
          </div>
        </div>
      </a>

      <div class="menu__border"></div>
    </menu>
    <div class="svg-container">
      <svg viewBox="0 0 202.9 45.5">
        <clipPath id="menu" clipPathUnits="objectBoundingBox" transform="scale(0.0049285362247413 0.021978021978022)">
          <path d="M6.7,45.5c5.7,0.1,14.1-0.4,23.3-4c5.7-2.3,9.9-5,18.1-10.5c10.7-7.1,11.8-9.2,20.6-14.3c5-2.9,9.2-5.2,15.2-7
            c7.1-2.1,13.3-2.3,17.6-2.1c4.2-0.2,10.5,0.1,17.6,2.1c6.1,1.8,10.2,4.1,15.2,7c8.8,5,9.9,7.1,20.6,14.3c8.3,5.5,12.4,8.2,18.1,10.5
            c9.2,3.6,17.6,4.2,23.3,4H6.7z" />
        </clipPath>
      </svg>
    </div>
  </div>
</template>

<style scoped>
.menu {
  @apply w-full flex text-xs bg-secondary-950 justify-center items-center z-10 fixed bottom-0 shadow-xl m-0;
  max-height: 75px;
  padding: 25px 0 2.85em;
  padding-bottom: 25px;
  -webkit-box-shadow: 0px 0px 17px 1px rgba(31, 171, 125, 0.56);
  -moz-box-shadow: 0px 0px 17px 1px rgba(31, 171, 125, 0.56);
  box-shadow: 0px 0px 17px 1px rgba(31, 171, 125, 0.56);
}

.menu__item {
  all: unset;
  flex-grow: 1;
  z-index: 100;
  display: flex;
  position: relative;
  border-radius: 50%;
  align-items: center;
  will-change: transform;
  justify-content: center;
  padding: 0.55em 0 0.85em;
  transition: transform var(--timeOut, var(--duration));
}

.menu__item::before {
  content: '';
  z-index: -1;
  width: 4.2em;
  height: 4.2em;
  border-radius: 50%;
  position: absolute;
  transform: scale(0);
  transition: background-color var(--duration), transform var(--duration);
}

.menu__item.active {
  transform: translate3d(0, -0.8em, 0);
}

.menu__item.active::before {
  transform: scale(1);
  /* background-color: #1fab7d; */
}

.icon {
  width: 2.6em;
  height: 2.6em;
  stroke: white;
  fill: transparent;
  stroke-width: 1pt;
  stroke-miterlimit: 10;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 400;
}

.menu__item.active .icon {
  animation: strok 1.5s reverse;
}

@keyframes strok {
  100% {
    stroke-dashoffset: 400;
  }
}

.menu__border {
  @apply bg-secondary-950;
  left: 0;
  bottom: 99%;
  width: 11em;
  height: 2em;
  position: absolute;
  clip-path: url(#menu);
  will-change: transform;
  transition: transform .5s;
  -webkit-box-shadow: 0px -6px 17px 1px rgba(31, 171, 125, 0.69);
  -moz-box-shadow: 0px -6px 17px 1px rgba(31, 171, 125, 0.69);
  box-shadow: 0px -6px 17px 1px rgba(31, 171, 125, 0.69);
}

.svg-container {
  width: 0;
  height: 0;
}

@media screen and (max-width: 50em) {
  .menu {
    font-size: 0.8em;
  }
}
</style>