<script lang="ts" setup>
import MiPixLogo from '~/assets/logo.json'
const runtimeConfig = useRuntimeConfig()
const whatsapp = ref<string>('553135001363');

onMounted(() => {
  //@ts-ignore
  anj_725d0078_96d7_4957_9ff1_0dfb6980fc97.init();
})
</script>

<template>
  <div>
    <div id="footer">
      <div class="w-full relative before-gradient"></div>
      <div class="footer-items">
        <div class="footer-mipix-info">
          <nuxt-link to="/">
            <Vue3Lottie :animationData="MiPixLogo" class="xl:!ml-8 !w-48" />
          </nuxt-link>
          <div class="footer-mipix-networks">
            <a href="https://www.facebook.com/profile.php?id=61556650960932" target="_blank"><img
                src="~/assets/images/icon-facebook.svg" /></a>
            <a href="https://www.instagram.com/mipix.bet" target="_blank"><img
                src="~/assets/images/icon-instagram.svg" /></a>
            <a href="https://www.tiktok.com/@mipix.bet" target="_blank"><img
                src="~/assets/images/icon-tiktok.svg" /></a>
            <a href="https://youtube.com" target="_blank"><img src="~/assets/images/icon-youtube.svg" /></a>
          </div>
        </div>
        <div class="footer-items-links">
          <div class="footer-links">
            <div class="link-section">Sobre MiPix</div>
            <div class="links-section">
              <nuxt-link :to="{ name: 'about' }">Sobre nós</nuxt-link>
              <nuxt-link :to="{ name: 'privacy' }">Politica de Privacidade</nuxt-link>
              <nuxt-link :to="{ name: 'terms-and-conditions' }">Termos e condições</nuxt-link>
              <nuxt-link :to="{ name: 'kyc-policy' }">Politica KYC</nuxt-link>
              <nuxt-link :to="{ name: 'betting-terms' }">Termos de apostas</nuxt-link>
            </div>
          </div>
          <div class="footer-links">
            <div class="link-section">Cassino</div>
            <div class="links-section">
              <nuxt-link :to="{ name: 'casino-lobby-slug', params: { slug: 'cassino-ao-vivo' } }">Cassino Ao
                Vivo</nuxt-link>
              <nuxt-link :to="{ name: 'casino-lobby-slug', params: { slug: 'slots' } }">Slots Games</nuxt-link>
              <nuxt-link :to="{ name: 'casino-lobby-slug', params: { slug: 'mines' } }">Mines Games</nuxt-link>
              <nuxt-link :to="{ name: 'promotions' }">Promoções</nuxt-link>
            </div>
          </div>
          <div class="footer-links">
            <div class="link-section">Melhores Slots</div>
            <div class="links-section">
              <nuxt-link
                :to="{ name: 'casino-providerId-gameId', params: { providerId: 'pgsoft', gameId: 'fortune-tiger' } }">Fortune
                Tiger</nuxt-link>
              <nuxt-link
                :to="{ name: 'casino-providerId-gameId', params: { providerId: 'pragmatic-play', gameId: 'sweet-bonanza' } }">Sweet
                Bonanza</nuxt-link>
              <nuxt-link
                :to="{ name: 'casino-providerId-gameId', params: { providerId: 'pragmatic-play', gameId: 'sugar-rush' } }">Sugar
                Rush</nuxt-link>
              <nuxt-link
                :to="{ name: 'casino-providerId-gameId', params: { providerId: 'pragmatic-play', gameId: 'big-bass-bonanza' } }">Big
                Bass Bonanza</nuxt-link>
              <nuxt-link
                :to="{ name: 'casino-providerId-gameId', params: { providerId: 'pragmatic-play', gameId: 'gates-of-olympus' } }">Gate
                Of Olympus</nuxt-link>
            </div>
          </div>
          <div class="footer-links">
            <div class="link-section">Crash Games</div>
            <div class="links-section">
              <nuxt-link
                :to="{ name: 'casino-providerId-gameId', params: { providerId: 'spribe', gameId: 'aviator' } }">Aviator</nuxt-link>
              <nuxt-link
                :to="{ name: 'casino-providerId-gameId', params: { providerId: 'pragmatic-play-live', gameId: 'spaceman' } }">Spaceman</nuxt-link>
              <nuxt-link
                :to="{ name: 'casino-providerId-gameId', params: { providerId: 'smartsoft', gameId: 'jetx' } }">JetX</nuxt-link>
              <nuxt-link
                :to="{ name: 'casino-providerId-gameId', params: { providerId: 'spribe', gameId: 'mines' } }">Mines</nuxt-link>
              <nuxt-link :to="{ name: 'casino-lobby-slug', params: { slug: 'crashgames' } }">Crash Games</nuxt-link>
            </div>
          </div>
          <div class="footer-links">
            <div class="link-section">Suporte</div>
            <div class="links-section">
              <a :href="`https://api.whatsapp.com/send?phone=${whatsapp}&text=Ol%C3%A1%2C%20MiPix%21`"
                target="_blank">Suporte 24/7</a>
              <nuxt-link :to="{ name: 'responsible-gaming' }">Jogo Responsável</nuxt-link>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-items before-gradient relative">
        <div class="flex flex-col justify-center items-center xl:items-start mt-4">
          <div class="font-[500] ml-2">Opções de pagamentos</div>
          <ul class="flex gap-8 mt-6 justify-center items-center">
            <li class="ml-1 items-center justify-around p-1 rounded-md">
              <img src="~/assets/images/pix-logo.svg" class="w-20 h-10" />
            </li>
            <li class="ml-1 items-center justify-around p-1 rounded-md">
              <img src="~/assets/images/boleto.svg" class="w-20 h-10" />
            </li>
            <li class="ml-1 items-center justify-around p-1 rounded-md">
              <img src="~/assets/images/cartao.svg" class="w-20 h-10" />
            </li>
          </ul>
        </div>
        <div class="w-full relative before-gradient mt-8 sm:hidden"></div>
        <div class="flex flex-col justify-center items-center sm:mt-4">
          <div class="text-xs font-semibold ml-2">&nbsp;</div>
          <ul class="grid grid-cols-3 sm:flex gap-x-4 justify-around items-center mt-4">
            <li class="ml-1 items-center justify-around p-1 rounded-md">
              <img src="~/assets/images/18.svg" class="w-9 mr-2" />
            </li>
            <li class="ml-1 items-center justify-around p-1 rounded-md">
              <img src="~/assets/images/selo1.svg" class="w-36" />
            </li>
            <li class="ml-1 items-center justify-around p-1 rounded-md">
              <img src="~/assets/images/selo2.svg" class="w-32" />
            </li>
            <li class="ml-1 mb-1 items-center justify-around p-1 rounded-md hidden sm:block">
              <img src="~/assets/images/selo3.svg" class="w-32" />
            </li>
            <li class="ml-1 items-center justify-around p-1 rounded-md hidden sm:block">
              <img src="~/assets/images/selo4.svg" class="w-24" />
            </li>
          </ul>
          <ul class="grid grid-cols-2 sm:hidden gap-x-4 justify-around items-center mt-4">
            <li class="ml-1 mb-1 items-center justify-around p-1 rounded-md">
              <img src="~/assets/images/selo3.svg" class="w-28" />
            </li>
            <li class="ml-1 items-center justify-around p-1 rounded-md">
              <img src="~/assets/images/selo4.svg" class="w-20" />
            </li>
          </ul>
        </div>
      </div>
      <div class="w-full relative before-gradient mt-4 sm:hidden"></div>
      <div class="w-full grid grid-cols-1 mt-8 sm:mt-0 sm:grid-cols-3 pb-8 px-2 gap-2 text-xs text-center justify-center items-center">
        <div class="mx-auto sm:mx-0 sm:justify-self-end w-24">
          <div id="anj-725d0078-96d7-4957-9ff1-0dfb6980fc97" data-anj-seal-id="anj-725d0078-96d7-4957-9ff1-0dfb6980fc97"
                data-anj-image-size="128" data-anj-image-type="basic-small"></div>
        </div>
        <p>MiPix é de propriedade e operado por MIPIX BET LIMITADA, número de registro: 4062001320359, endereço
          registrado: San Pedro, Barrio Dent, Del Centro Cultural Costa Ricense Norteamericano, Doscientos Metros Al No
          Rte Y Cincuenta Al Este, Edificio Ofident, Oficina Nu Mero Tres. Contate-nos em support@mipix.bet. MiPix é
          licenciado e regulamentado pelo Governo da Ilha Autônoma de Anjouan, União das Comores, e opera sob a Licença
          nº 3-102-901381. MiPix cumpriu todas as normas regulatórias e está legalmente autorizada a realizar operações
          de jogos de azar e apostas para qualquer e todos os jogos de sorte e apostas.</p>
      </div>
      <div
        class="w-full py-4 px-2 xl:p-4 xl:px-8 flex-col xl:flex-row gap-y-2 text-center xl:text-left flex items-center justify-between before-gradient relative">
        <div>
          {{ new Date().getFullYear() }} - Todos os direitos reservados /
          <span>v{{ runtimeConfig.public.clientVersion }}</span>
        </div>
        <span>Jogar pode ser viciante. Por favor, jogue com responsabilidade.</span>
      </div>
    </div>

    <MobileFooterMenu />
  </div>
</template>

<style lang="css" scoped>
#footer {
  @apply w-full min-h-[350px] grid grid-cols-1 xl:flex xl:flex-col pb-20 xl:pb-0 xl:px-24 items-center justify-evenly bg-secondary-950 mt-20
}

#footer .footer-items {
  @apply w-full px-4 py-4 mt-16 grid grid-cols-1 xl:flex items-start justify-between
}

#footer .footer-items .footer-items-links {
  @apply w-full grid grid-cols-3 mt-8 xl:mt-0 xl:flex items-start justify-between
}

#footer .footer-mipix-info {
  @apply grid grid-cols-2 xl:flex xl:flex-col justify-center
}

#footer .footer-mipix-networks {
  @apply w-full flex justify-center gap-2 mt-2
}

#footer .footer-mipix-networks a {
  @apply hover:opacity-80
}

#footer .footer-mipix-networks a img {
  @apply w-12 xl:w-7
}

#footer .footer-links {
  @apply block px-4
}

#footer .footer-links a {
  @apply hover:text-gray-400 font-medium
}

#footer .footer-links .link-section {
  @apply text-primary-300 font-bold xl:text-base mb-4
}

#footer .footer-links .links-section {
  @apply flex flex-col gap-y-2
}
</style>